import { createIntl, IntlErrorCode } from "@formatjs/intl";
import { AVAILABLE_LOCALES_ARR_DASH } from "./common";
import { CacheInterface } from "./types";
import _ from "lodash";

export function logError(err: unknown, context: any) {
  if (context) {
    if (err instanceof Error) {
      //@ts-ignore
      err.context = context;
    } else {
      console.error("Ollie i18n error! Context:");
      console.error(context);
    }
  }

  if (process.env["NODE_ENV"] === "development") {
    throw err;
  } else {
    console.error(err);
  }
}

export async function fetchWithTimeout(resource: string, options?: RequestInit & { timeout?: number }) {
  const { timeout = 5000 } = options || {};

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });
  clearTimeout(id);
  return response;
}

export const normalizeLocale = _.memoize(locale => {
  if (!locale) {
    return "en-US";
  }
  let [lang, country] = locale.split(/([a-z]{2,3})(?:[-_]([a-z]{2,3}))?/i)?.slice(1) || [];

  if (!lang) {
    return "en-US";
  }

  if (!country && lang.match(/^en$/i)) {
    country = "US";
  }

  return [lang, country?.toUpperCase()].filter(Boolean).join("-");
});

const ACCENTED_MAP: Record<string, string> = {
  a: "ȧ",
  A: "Ȧ",
  b: "ƀ",
  B: "Ɓ",
  c: "ƈ",
  C: "Ƈ",
  d: "ḓ",
  D: "Ḓ",
  e: "ḗ",
  E: "Ḗ",
  f: "ƒ",
  F: "Ƒ",
  g: "ɠ",
  G: "Ɠ",
  h: "ħ",
  H: "Ħ",
  i: "ī",
  I: "Ī",
  j: "ĵ",
  J: "Ĵ",
  k: "ķ",
  K: "Ķ",
  l: "ŀ",
  L: "Ŀ",
  m: "ḿ",
  M: "Ḿ",
  n: "ƞ",
  N: "Ƞ",
  o: "ǿ",
  O: "Ǿ",
  p: "ƥ",
  P: "Ƥ",
  q: "ɋ",
  Q: "Ɋ",
  r: "ř",
  R: "Ř",
  s: "ş",
  S: "Ş",
  t: "ŧ",
  T: "Ŧ",
  v: "ṽ",
  V: "Ṽ",
  u: "ŭ",
  U: "Ŭ",
  w: "ẇ",
  W: "Ẇ",
  x: "ẋ",
  X: "Ẋ",
  y: "ẏ",
  Y: "Ẏ",
  z: "ẑ",
  Z: "Ẑ"
};

export function pseudoLocalize(str: string) {
  let newStr = "";

  for (let i = 0; i < str.length; i++) {
    const char = str[i]!;
    const newChar = ACCENTED_MAP[char] ?? char;
    newStr += newChar;
  }

  return newStr;
}

export const getBestFitLocale = _.memoize(function getBestFitLocale(localeRaw: string) {
  const locale = normalizeLocale(localeRaw);
  const [lang, country] = locale.split("-");

  let decentMatch: string | undefined;

  for (let i = 0; i < AVAILABLE_LOCALES_ARR_DASH.length; i++) {
    const thisLocale = AVAILABLE_LOCALES_ARR_DASH[i]!;

    if (thisLocale === locale) {
      return thisLocale;
    }

    const [thisLang, thisCountry] = thisLocale.split("_");
    if (thisLang === lang) {
      if (country && thisCountry === country) {
        return thisLocale;
      }

      decentMatch = thisLocale;
    }
  }

  return decentMatch || "en_US";
});

export async function fetchMessages(locale: string, asyncStorage?: CacheInterface | null) {
  locale = locale.replace(/-/g, "_");

  const url = `https://ollie-i18n.web.app/${locale}.json`;

  //Attempt to fetch messages 3 times...
  async function doFetch(attempt: number): Promise<any> {
    try {
      return await fetchWithTimeout(url, {
        timeout: 1500,
        headers: { pragma: "no-cache", "cache-control": "no-cache" }
      }).then(r => {
        if (!r.ok) {
          throw new Error(`Unable to fetch messages. Status ${r.status}. ${r.statusText}`);
        }
        return r.json();
      });
    } catch (e) {
      if (attempt < 3) {
        await new Promise(r => setTimeout(r, 200));
        return await doFetch(attempt + 1);
      } else {
        throw e;
      }
    }
  }

  const messages = await doFetch(0);

  if (asyncStorage) {
    asyncStorage.setItem(locale, messages).catch(e => {
      logError(e, "error saving messages to async storage with locale " + locale);
    });
  }

  return messages;
}

export function createFormatJSWrapper(locale: string, messages: Record<string, string>) {
  return createIntl({
    locale: locale.replace("_", "-"),
    messages,
    onError: err => {
      if (err && err.code && (IntlErrorCode as any)[err.code]) {
        if (err.code === IntlErrorCode.MISSING_TRANSLATION && process.env["NODE_ENV"] === "development") {
          //Do nothing. We're in development and are just missing some strings...
        } else {
          try {
            logError(err, "i18n code error");
          } catch (e) {}
        }
      } else {
        logError(err, "Unknown i18n error");
      }
    }
  });
}
